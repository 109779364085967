import './jquery-esm';

$(window).on('load', function () {
  $('.loader').addClass('inactive');
});

$('#menuButton').click(function () {
  $('.menuHolder').addClass('menu-active');
});

$('.menuHolder').on('click',function () {
  $('.menuHolder').removeClass('menu-active');
});
